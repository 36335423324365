<template>
  <div class="content">
    <div class="Preview-Main">
      <div class="Preview-Parent" id="zkviewParent">
        <div
          style="height: 100%; width: 100%; overflow: hidden"
          id="zkScrollBox"
        >
          <div
            class="zk-view-preview animate__animated animate__null animate__null"
            id="zkviewContent"
          ></div>
        </div>
      </div>
    </div>
    <!-- 虚拟dom -->
    <div
      id="zk_render_dom"
      style="position: fixed; left: 10000px; top: 10000px; overflow: hidden"
    ></div>
  </div>
</template>
<script>
export default {
  name: "equipment",
  props: {},
  mounted() {
    window.zkViewStaticRootPath = "./topo";
    // window.previewOpen('54cbc58f-1528-4f0c-845b-a601c348963f') // 诊断二级页面
    window.previewOpen('8b7af0cb-f1d3-4cbe-85d3-2a3b817289e8')
  },
};
</script>

<style lang="scss" scoped>

.content {
  width: 100%;
  height: calc(100vh - 140px);
  color: #ffffff;
  position: relative;
  margin-top: 0;
}
.Preview-Main {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.Preview-Parent {
  height: 100%;
  width: 100%;
  background-color: rgba(0, 33, 65, 1);
}

.zk-view-preview {
  position: relative;
  overflow: hidden;
}

</style>